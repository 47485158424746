@import '@fortawesome/fontawesome-svg-core/styles.css';

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    Lato,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif !important;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* disables back/forward gesture bc keeps getting triggered when horizontal scrolling */
  overscroll-behavior-x: none;
}

#__next {
  height: 100%;
  width: 100%;
}

body > * {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Used to set the TextareaAutosize component from MUI's placeholder to match other placeholders */
.placeholder-color::placeholder {
  color: rgb(24, 32, 38, 0.4);
}
.show-testids *[data-testid]::after {
  position: absolute;
  right: 0;
  color: #333;
  background-color: #fcc;
  content: attr(data-testid);
  font-size: 20px;
  pointer-events: none;
}

.navMenuClipContainer,
.logoClipContainer {
  row-gap: var(--spacing6);
  max-width: var(--menu-width);
  width: var(--menu-width);
  overflow: hidden;
  transition: var(--menu-transition);
}

.logoClipContainer {
  position: relative;
  left: 0;
  width: 212px;
  height: 44px;
  margin-top: var(--spacing3);
  overflow: hidden;
}

.collapse-menu .logoClipContainer {
  left: 22px;
  width: 24px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 5px;
}

.logoClipContainer > svg {
  position: absolute;
  inset: 0;
  margin-left: var(--spacing4);
  transition: var(--menu-transition);
  width: 140px;
}

.collapse-menu .logoClipContainer > svg {
  left: -133px;
  width: 171px;
}

.calendar-container {
  position: relative;
}

.calendar-container .right-panel {
  position: absolute;
  top: 41px;
  right: 1px;
  bottom: 31px;
  width: 298px;
  z-index: 20;
  overflow: hidden;
}

.calendar-container .right-panel .day-date-picker {
  border-bottom: 1px solid var(--lightDividerBlack);
}

.calendar-container .right-panel .day-summary {
  flex-grow: 1;
  overflow: auto;
  padding: 10px;
  background-color: var(--calcite);
}

.KStaticDatePicker .MuiPickerStaticWrapper-content {
  min-width: auto !important;
}

.KStaticDatePicker .MuiPickerStaticWrapper-content .PrivatePickersSlideTransition-root {
  min-height: 110px !important;
}

.MuiAccordion-root.Mui-expanded + .action-buttons {
  margin-top: calc(var(--spacing2) * -1px);
}

.rotate {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.KTableToolbar-container {
  border: 1px solid var(--lightDividerBlack);
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* Enables hiding x- and y-axis hover text in Plotly charts */
.hide-axis-text .axistext {
  display: none;
}

.always-show-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

.always-show-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/** Begin  colors.css */
:root {
  --darkSlateBlue: rgb(18, 38, 80);
  --darkIndigo: rgb(18, 40, 88);
  --cobalt: rgb(32, 63, 131);
  --cobaltDisabled: rgba(32, 63, 131, 0.5);
  --cobaltHover: rgba(32, 63, 131, 0.75);
  --cobaltLight: rgba(32, 63, 131, 0.2);
  --cobaltLightHover: rgba(32, 63, 131, 0.1);
  --frenchBlue: rgb(60, 92, 170);
  --lightBadges7PrimaryMinimal: rgba(19, 124, 189, 0.2);
  --lightTableRowEven: rgb(255, 255, 255);
  --lightTableRowOdd: rgb(242, 245, 247);
  --lightDividerBlack: rgba(16, 22, 26, 0.15);
  --lightIconDisabled: rgba(92, 112, 128, 0.5);
  --lightDatePickerRange: rgb(235, 241, 245);
  --lightIconDefault: rgb(92, 112, 128);
  --lightBadges6DefaultMinimal: rgba(138, 155, 168, 0.2);
  --lightIconWhite: rgb(255, 255, 255);
  --lightIconSuccess: rgb(13, 128, 80);
  --lightBadges3Success: rgb(15, 153, 96);
  --lightBadges8SuccessMinimal: rgba(15, 153, 96, 0.2);
  --lightIconWarning: rgb(191, 115, 38);
  --lightBadges4Warning: rgb(217, 130, 43);
  --lightIconStarSelected: rgb(242, 184, 36);
  --lightBadges9WarningMinimal: rgba(217, 130, 43, 0.2);
  --lightIconDangerDarker: rgb(194, 48, 48);
  --lightIconDanger: rgb(222, 67, 67);
  --lightBadges5Danger: rgb(219, 55, 55);
  --lightBadges10DangerMinimal: rgba(219, 55, 55, 0.2);

  --pageBackgroundColor: rgb(249, 247, 254);
  --textColor: var(--dark);
  --backgroundLight: #f7f7f7;
  --lightTeal: rgb(138, 199, 203);
  --pagination: #738c9a;
  --advancedFilteringBackgroundColor: #e7ecf5;

  --calcite: #fff;
  --darkBlueGrey: #2b2352;
  --veryLightBlue: #e7ecf5;
  --dark: rgb(24, 32, 38);

  --color: #d7d8d8;

  --group: rgb(0, 142, 188);
  --grouped: rgba(0, 152, 158, 0.5);

  --statusLightBlue: #008ebc;
  --statusRed: #ab2869;
  --statusGreen: #0f9960;
  --statusBlack: #182026;
}

/** Begin inputs.css */

.inputsRowChildren {
  margin: var(--spacing2);
  flex-wrap: wrap;
  gap: var(--spacing2);
  column-gap: var(--spacing6);
  /* todo build issue */
  align-items: flex-start !important;
}

.inputsRowChildren .inputsRowChildren {
  margin: 0;
}

.inputsRowChildren > *,
.inputsRowChildren > * > * {
  width: var(--inputsWidth);
}

.inputsRowChildren > .KDivider {
  padding: 0;
  margin: 0;
  flex-grow: 1;
  width: 100%;
  max-width: none;
}

/* no MUI theme support for textarea :< */
.inputsRowChildren textarea {
  border: none;
  box-shadow: inset 0 0 0 1px var(--lightDividerBlack);
  border-radius: 3px;
}

/* phone input styles */
/*
    This css is used to override styles that create the basic material ui look
    for the react-phone-input-2 PhoneInput Component
*/

/* Styles to add a red asterisk next to the special label if the parent class is required */
.k-phone-input-container.required > .special-label:after {
  color: rgb(219, 55, 55);
  content: ' *';
  height: 10px;
  width: 10px;
}

.k-phone-input {
  width: var(--inputsWidth) !important;
  border-radius: 3px !important;
  background-color: #fff;
  min-height: 40px;
  max-height: 40px;
  height: 40px;
  box-shadow: inset 0 0 0 1px var(--lightDividerBlack) !important;
}

/*
    Label for the PhoneInput Component
*/
.special-label {
  color: rgb(24, 32, 38);
  font-family: Lato, sans-serif;
  padding: 0 !important;
  background: none !important;
  position: relative !important;
  font-size: 14px !important;
  line-height: 1.29;
  margin-bottom: 5px;
  top: unset !important;
  left: unset !important;
}

.react-tel-input .flag-dropdown {
  top: 22px !important;
}

/*
  Borders of the PhoneInput Component
*/
.react-tel-input .form-control {
  border: none !important;
}

/*
   Helper Text or Warning Text for the PhoneInput Component
*/
.react-tel-input .form-control.invalid-number {
  border: none !important;
}

/*
    Font properties for the Helper Text/Error Message for the PhoneInput Component
*/
.react-tel-input .invalid-number-message {
  position: absolute;
  z-index: 1 !important;
  font-size: 0.75rem !important;
  left: -6px !important;
  top: 62px !important;
  color: #f05c49 !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.03333em;
}

/** begin vars.css */
:root {
  /** theme */
  --spacing0: 0px;
  --spacing1: 5px;
  --spacing2: 10px;
  --spacing3: 20px;
  --spacing4: 30px;
  --spacing5: 40px;
  --spacing6: 50px;
  --appBarHeight: 92px;
  --dialogWidth: 450px;

  /** KStack */
  --childWidth: auto;
  --childHeight: 100%;
  --inputsWidth: 363px;

  /** KTable */
  --cellFontSize: 14px;

  /** ScheduleConfigCalendar */
  --gutter: 550px;
  --countDaysToShow: 7;

  /** Nav Menu */
  --menu-width: 212px;
  --menu-right-offset: 0px;
  --menuBackgroundColor: #29234f;
  --menu-text-color-collapseable: #fff;
  --menu-item-hover-background-color: rgba(255, 255, 255, 0.3);
  --menu-transition-time: 1s;
  --menu-transition: all var(--menu-transition-time) ease, color 0.5s ease-out;
}

.collapse-menu {
  --menu-width: 66px;
  --menu-text-color-collapseable: transparent;
  --menu-right-offset: 10px;
  --cellFontSize: 14px;
}

.set-child-width > * {
  width: var(--childWidth);
}

.set-child-height > * {
  height: var(--childHeight);
}

.font {
  font-family: Lato, sans-serif;
}

/** Begin index.css */
@font-face {
  font-family: 'Lato';
  src: url(/fonts/Lato/Lato-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Lato Bold';
  src: url(/fonts/Lato/Lato-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'Lato Italic';
  src: url(/fonts/Lato/Lato-Italic.ttf) format('opentype');
}

@font-face {
  font-family: 'Lato Light';
  src: url(/fonts/Lato/Lato-Light.ttf) format('opentype');
}
@font-face {
  font-family: 'Lato Thin';
  src: url(/fonts/Lato/Lato-Thin.ttf) format('opentype');
}

.fixed-item-width-button-group button {
  min-width: 4em;
  width: 4em;
  max-width: 4em;
}

.div-with-dot {
  position: relative;
  padding-right: 20px;
}

.div-with-dot::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -6px;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  background-color: #ab2869;
  border-radius: 50%;
}


.parent {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr 3fr 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.div1 { grid-area: 1 / 1 / 4 / 2; }
.div2 { grid-area: 1 / 2 / 4 / 3; }
.div3 { grid-area: 1 / 3 / 4 / 4; }

.data-parents {
  position: fixed;
  top: 170px;
  left: 20px;
}

.data-targets {
  position: fixed;
  bottom: 70px;
  right: 20px;
}

.maintenance-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.maintenance-content {
  grid-area: 2 / 2 / 12 / 12;
  padding: 50px 158px 0 158px;
  border-radius: 1px;
  border: solid 1px #10161a26;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maintenance-content > p {
  max-width: 768px;
  font-size: 17px;
}

.maintenance-img {
  height: 460px;
  width: 460px;
  background-image: url(maintence.png);
  background-repeat:no-repeat;
  background-size: 80%;
  background-position:center;
}

@media only screen and (max-width:1020px) {
  .maintenance-img {
    height: 410px;
    width: 410px;
  }
}

@media only screen and (max-width:640px) {
  .maintenance-content {
    padding: 25px 79px 0 79px;
  }

  .maintenance-img {
    height: 310px;
    width: 310px;
  }
}


